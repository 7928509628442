import React from "react";

export const CheckInDisclaimer = () => {
  const currentDate = new Date();
  const closureEndDate = new Date("2023-09-14");
  const shouldShowClosureMessage = currentDate < closureEndDate;
  return (
    <div className="check-in__disclaimer">
      {shouldShowClosureMessage && (
        <>
          <p>
            Note: The second-floor galleries will be closed May 30–Sept. 13 for
            floor refinishing.
          </p>
          <br />
        </>
      )}

      <p>Tickets are non-refundable.</p>
      <br />
      <p>
        Please note that guests should avoid bringing large bags unless
        necessary for a medical reason. All bags will be inspected upon entry.
        Backpacks, backpack-style child-carriers, oversize strollers, umbrellas,
        bulky or long coats, and bags and packages larger than 12 x 10 inches
        must be stowed in our free coat check or lockers on the Lower Level.
      </p>
      <br />

      <p>
        We ask that everyone be respectful of one another, the artwork, and our
        facility to provide a safe and inspiring environment for all. The Barnes
        will not tolerate discrimination, harassment, or any behavior or
        language that is abusive, offensive, or unwelcome. Anyone who does not
        meet this standard will be asked to leave.
      </p>
      <br />

      <p>
        For more details, visit our{" "}
        <a
          href="https://www.barnesfoundation.org/plan-your-visit"
          target="_blank"
          rel="noreferrer"
          className="a-basic-link"
        >
          website.
        </a>
      </p>
      <br />
      <p>
        Contributions to the Barnes Foundation, including those in support of
        Calder Gardens, are deductible for federal income tax purposes in
        accordance with applicable law. For more information, see our{" "}
        <a
          href="https://www.barnesfoundation.org/support/disclosure-statement/"
          target="_blank"
          rel="noreferrer"
          className="a-basic-link"
        >
          Charitable Disclosure Statement
        </a>
        .
      </p>
      <br />
    </div>
  );
};
