import React, { useState, useEffect } from "react";
import { EventTimeObject } from "acme-ticketing-client";
import { SpinnerSuspense } from "../shared/spinner";
import { formatTimeString } from "../../util/formatting";
import { KioskModal } from "../kiosk/kioskModals";

type EventDetailTimeSelect = {
  times?: EventTimeObject[] | void;
  selectedTime?: EventTimeObject;
  setSelectedTime: (time: EventTimeObject) => void;
  isActive: boolean;
};

/**
 * Wrap TimeSelect, so on TimeSelect mount intro animation can perform.
 */
export const EventDetailTimeSelect: React.FC<EventDetailTimeSelect> = ({
  times,
  selectedTime,
  setSelectedTime,
  isActive,
}: EventDetailTimeSelect) => (
  <SpinnerSuspense isLoaded={Boolean(times)}>
    <TimeSelect
      times={times}
      selectedTime={selectedTime}
      setSelectedTime={setSelectedTime}
      isActive={isActive}
    />
  </SpinnerSuspense>
);

const TimeSelect: React.FC<EventDetailTimeSelect> = ({
  times,
  selectedTime,
  setSelectedTime,
  isActive,
}: EventDetailTimeSelect) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <ul
      className="time-select"
      style={{
        opacity: isLoaded ? 1 : 0.3,
        maxHeight: isActive && isLoaded ? "100%" : 0,
      }}
    >
      {/* Check if there are time slots available for selected day */}
      {times && Object.keys(times).length ? (
        times.map((timeSlot) => {
          const formattedTime = formatTimeString(timeSlot.time);
          const hasSeatsAvailable = timeSlot.availableSeats > 0;

          let timeSlotClassNames = "time-select__button";

          // If timeslot has no available tickets
          if (!hasSeatsAvailable) {
            timeSlotClassNames = `${timeSlotClassNames} ${timeSlotClassNames}--disabled`;

            // If timeslot is selected
          } else if (selectedTime && timeSlot.id === selectedTime.id) {
            timeSlotClassNames = `${timeSlotClassNames} ${timeSlotClassNames}--selected`;
          }

          return (
            <li className={timeSlotClassNames} key={timeSlot.id}>
              <button
                onClick={() => {
                  // Only set timeslot if we have tickets available.
                  if (hasSeatsAvailable) {
                    setSelectedTime(timeSlot);
                  }
                }}
              >
                <span>{formattedTime}</span>
                <span className="time-select__warn">
                  {hasSeatsAvailable
                    ? `Tickets Available: ${timeSlot.availableSeats}`
                    : "Sold Out"}
                </span>
              </button>
            </li>
          );
        })
      ) : (
        // If there are no time slots available for selected day, show error modal
        // Note: This should only happen on kiosk, where the day is set to today by default
        <KioskModal
          header={"There are no available tickets for today's date."}
          body={
            "Check in with the box office to determine options for your visit. Thank you!"
          }
          onContinue={closeModal}
          continueText={"Close"}
          dismissable={true}
        />
      )}
    </ul>
  );
};
